jQuery.noConflict();
jQuery(document).ready(function($){		
	/* toggle nav */
	$( "#menu-icon" ).keydown(function(e){ 
		if(e.keyCode == 13) {
			$("#dl-menu").slideToggle("slow");
			$(this).toggleClass("active");
		}
	});
	$("#menu-icon").on("click", function(){
		$("#dl-menu").slideToggle("slow");
		$(this).toggleClass("active");
	});
	$("#menu-icon2").on("click", function(){
		$("#sub2").slideToggle("slow");
		$(this).toggleClass("active");
	});

});